<template>
  <div v-if="getLoadingFromAuthentication" class="text-center">
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "logout",
  computed: mapGetters(["getLoadingFromAuthentication"]),
  methods: {
    ...mapActions(["logout"])
  },
  created() {
    this.logout().finally(() => {
      this.$router.push("/");
    });
  }
};
</script>
